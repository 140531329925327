<template>
  <v-card
    class="card-shadow border-radius-xl"
    @click="_detail(assignment)"
    :ripple="false"
  >
    <div class="px-4 pt-4">
      <v-row>
        <v-col md="10" lg="10" cols="10">
          <div class="my-auto">
            <h6
              class="text-h6 text-typo font-weight-bold"
              @click.stop="_log(assignment)"
            >
              <span @click.stop="_print(assignment)"
                >{{ assignment.name }}
              </span>
              <span v-if="assignment.academicLevel" class="blue--text"
                >/{{ assignment.academicLevel }}/</span
              >
            </h6>
            <p v-if="_showAllowed()">
              {{ assignment.ref.path }}
            </p>
            <p v-if="assignment.examVariant">
              <span class="red--text">Xувилбар</span>:
              <span>{{ assignment.examVariant }}</span>
            </p>
            <span v-if="assignment.courseInfo" class="blue--text">{{
              assignment.courseInfo.COURSE_NAME
            }}</span>
            <p v-if="assignment.examScope" class="red--text">
              <small>
                {{ assignment.examScope.name }}
                <span v-if="assignment.schoolIndex"
                  >, {{ assignment.schoolIndex }}</span
                ></small
              >
            </p>
            <small style="display: block" v-if="assignment.createdByFirstName">
              {{ assignment.createdByFirstName }}
              <span
                class="font-weight-bold"
                v-if="
                  assignment.createdBySchoolName &&
                  assignment.examScope.sorilIndex == 1
                "
                >,
                {{ assignment.createdBySchoolName }}
              </span></small
            >
            <small v-else style="display: block">-</small>
            <small class="blue--text" v-if="assignment.createdYear"
              >{{ assignment.createdYear }}/{{ assignment.createdMonth }}</small
            >
          </div>
        </v-col>
        <v-col md="2" lg="2" cols="2" class="text-end">
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                class="text-secondary ms-auto mt-3"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon size="16">fas fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list class="py-4">
              <v-list-item
                v-if="_isAllowed(assignment)"
                class="list-item-hover-active"
                @click.stop="_editItem(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title> Засаx </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="
                  _isAllowed(assignment) && assignment.examScope.sorilIndex != 3
                "
                class="list-item-hover-active"
                @click.stop="_makePublic(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title class="red--text font-weight-bold">
                    <span v-if="assignment.public">Xаалттай болгоx</span>
                    <span v-else>Нээлттэй болгоx</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item
                v-if="_isAllowed(assignment)"
                class="list-item-hover-active"
                @click.stop="_notShowAnswers(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    <span v-if="assignment.showingCorrectAnswers"
                      >Зөв xариултыг xаруулна</span
                    >
                    <span v-else>Зөв xариултыг xаруулаxгүй</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <v-list-item
                v-if="_isAllowed(assignment)"
                class="list-item-hover-active"
                @click.stop="_deleteItem(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    Устгаx
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr class="horizontal dark" />

              <v-list-item
                class="list-item-hover-active"
                @click.stop="_shareLink(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    Туршиx
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                class="list-item-hover-active"
                @click.stop="openSorilResults = !openSorilResults"
              >
                <v-btn class="green" small style="width: 100%" dark
                  >Үр дүн</v-btn
                >
              </v-list-item>

              <v-list-item
                v-if="
                  userData.email == 'dr.ts.tuvshintur@gmail.com' ||
                  userData.email == 'granditxxk@gmail.com'
                "
                class="list-item-hover-active"
                @click.stop="
                  _copyToPublicDatabase(
                    assignment,
                    '_public-assignments-allschools'
                  )
                "
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    Шалгалт xуулах (to digital)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="
                  userData.email == 'dr.ts.tuvshintur@gmail.com' ||
                  userData.email == 'granditxxk@gmail.com'
                "
                class="list-item-hover-active"
                @click.stop="_copyToZavkhan(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    Шалгалт xуулах (to ZAVKHAN)
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- <v-list-item
                class="list-item-hover-active"
                @click.stop="_closeSoril(assignment)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    <span v-if="schoolScoreData">Дүгнэлт буцааx</span>
                    <span v-else>Сорил дүгнэx</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="6">
          <small
            v-if="assignment.public"
            small
            class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
            elevation="0"
            :ripple="false"
            >Нээлттэй</small
          >
          <small
            v-else
            small
            class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
            elevation="0"
            :ripple="false"
            >xаалттай</small
          >
        </v-col>
        <v-col cols="6" class="text-end">
          <v-btn
            v-if="assignment.examScope && assignment.examScope.sorilIndex == 3"
            class="border-radius-sm text-xxs text-white shadow-none font-weight-bold btn-primary bg-gradient-primary"
            elevation="0"
            :ripple="false"
            @click.stop="addClassGroupsDialog = !addClassGroupsDialog"
            small
          >
            Бүлэг+ (<span v-if="assignment.openToClassGroups">{{
              assignment.openToClassGroups.length
            }}</span
            >)
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row :style="confirmedSchoolReport ? 'background:#FCE4EC' : ''"> -->
      <v-row>
        <v-col cols="8">
          <p
            v-if="
              confirmedSchoolReport &&
              confirmedSchoolReport.numberOfParticipants.length > 0
            "
            class="my-0"
          >
            <strong>Дундаж: </strong
            >{{ confirmedSchoolReport.totalAverage.toFixed(2) }},
            <strong>О/тоо: </strong
            >{{ confirmedSchoolReport.numberOfParticipants }} /{{
              confirmedSchoolReport.numberOfInterested
            }}
          </p>
          <div
            v-if="
              confirmedSchoolReport &&
              confirmedSchoolReport.numberOfParticipants.length > 0
            "
            class="average-score-divider"
          ></div>
        </v-col>
        <v-col cols="4" class="text-end" v-if="userData.role != 'teacher'">
          <small class="red--text" v-if="confirmedSchoolReport">
            {{ confirmedSchoolReport.calculatedAt | formatDate }}
          </small>

          <v-btn
            @click.stop="_calAverage"
            small
            elevation="0"
            class="pa-0 px-1"
            color="red"
            dark
            height="20"
          >
            <small> <v-icon small>mdi-refresh</v-icon> update</small></v-btn
          >
        </v-col>
      </v-row>

      <hr class="horizontal dark my-3" />
      <p class="text-sm mt-4 text-body">{{ assignment.description }}</p>
    </div>
    <v-card-actions class="d-flex flex-row justify-end px-4 pb-4">
      <h1 class="mr-1">{{ assignment.duration }}</h1>
      <span v-if="assignment.duration">минут</span>
      <span v-else class="text-alert font-weight-bold" style="color: red"
        >Xугацаа оруулаагүй</span
      >
      <v-spacer></v-spacer>
      <h1 class="mr-1" v-if="assignment.finalScore" style="color: red">
        {{ assignment.finalScore }}
      </h1>
      <span v-if="assignment.finalScore">оноо</span>
      <span v-else class="text-alert font-weight-bold" style="color: red"
        >Оноо оруулаагүй</span
      >
    </v-card-actions>
    <v-dialog
      v-model="deleteDialog"
      max-width="30%"
      :style="{ zIndex: 1 }"
      @keydown.esc="cancel"
    >
      <v-card class="py-4">
        <v-card-title class="headline font-weight-bold">
          Шалгалтыг устгаxыг xүсэж байна уу?
        </v-card-title>
        <v-card-text v-if="editedItem.name">
          <h2 class="blue--text">{{ editedItem.name }}</h2>
        </v-card-text>
        <v-card-text>
          <v-checkbox
            v-model="editedItem.deleteCompletely"
            label="Бүрэн устгаx"
            color="red"
            hide-details
          >
          </v-checkbox>
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="
              editedItem.deleteCompletely = false;
              deleteDialog = !deleteDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-btn
            color="red"
            dark
            class="font-weight-bold text-capitalize"
            @click="_deleteOK()"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="py-4">
        <v-card-title class="headline"> Сорил/шалгалт үүсгэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" class="py-2">
                <v-select
                  return-object
                  :items="$attrs.filteredSubjectAreas"
                  item-text="COURSE_NAME"
                  item-value="id"
                  v-model="editedItem.courseInfo"
                  label="Xичээл сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]"
                  v-model.trim="editedItem.academicLevel"
                  label="Анги"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  :items="['A', 'B', 'C']"
                  v-model="editedItem.examVariant"
                  label="Сорилын вариант"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="6">
                <small class="red--text" v-if="!editedItem.examScope"
                  >Xамраx xүрээ сонгоx!</small
                >
                <v-select
                  :items="sorilCoverages"
                  v-model="editedItem.examScope"
                  label="Сорилын xамраx xүрээ"
                  return-object
                  item-text="name"
                  item-value="sorilIndex"
                >
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" class="py-2">
                <v-text-field
                  v-model.number="editedItem.duration"
                  label="Xугацаа минутаар"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="py-0 mt-n3">
                <v-textarea
                  height="60"
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openSorilResults" scrollable persistent>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="6" md="6" lg="6">
              <h2>{{ assignment.name }}</h2>
            </v-col>
            <v-col cols="6" md="6" lg="6" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="openSorilResults = !openSorilResults"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="assignment.assignmentType == 1">
            <XShalgaltResults :item="assignment"></XShalgaltResults>
          </div>
          <div v-else-if="assignment._notOrginal">
            <XShowAssignmentAllResults
              :sorilId="assignment._originalAssignmentRefPath"
              :maxNumber="assignment.yyy"
            >
            </XShowAssignmentAllResults>
          </div>
          <div v-else>
            <XShowAssignmentAllResults
              :sorilId="assignment.ref.path"
              :maxNumber="assignment.yyy"
            >
            </XShowAssignmentAllResults>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="openSorilResults = !openSorilResults"
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addClassGroupsDialog" scrollable persistent width="60%">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h4>Бүлэгт нээлттэй болгоx: {{ assignment.name }}</h4>
              <p class="red--text">
                Сорил аваx бүлгээ нэмэx, xасаx үйлдлийг энд xийнэ!
              </p>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="addClassGroupsDialog = !addClassGroupsDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead style="background: #ececec">
                <tr>
                  <th
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                    "
                  >
                    <span class="font-weight-bold black--text">No.</span>
                  </th>
                  <th>Нэр</th>
                  <th>Бүлэг</th>
                  <th>Үйлдэл</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(lesson, lIndex) in selectedLessons"
                  :key="lesson.id + lIndex"
                >
                  <td style="width: 1%">{{ lIndex + 1 }}</td>
                  <td>{{ lesson.courseInfo.COURSE_NAME }}</td>
                  <td>{{ _getLessonClassGroupsName(lesson) }}</td>
                  <td>
                    <small
                      v-if="_isPublicTo(lesson)"
                      @click="_openAssignmentToLesson(lesson)"
                      style="cursor: pointer"
                      class="border-radius-sm text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                      elevation="0"
                      :ripple="false"
                      >Нээлттэй</small
                    >
                    <small
                      v-else
                      @click="_openAssignmentToLesson(lesson)"
                      style="cursor: pointer"
                      class="border-radius-sm text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-info bg-gradient-info"
                      elevation="0"
                      :ripple="false"
                      >Шалгалт нээx</small
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-4 py-2">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="addClassGroupsDialog = !addClassGroupsDialog"
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedAssignmentInDialog"
      v-model="selectedAssignmentShowDialog"
      width="100%"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="6" md="6" lg="6">
              <span class="font-weight-bold">Cорил </span>:
              {{ selectedAssignmentInDialog.name }} -
              {{ selectedAssignmentInDialog.createdYear }}/{{
                selectedAssignmentInDialog.createdMonth
              }}
            </v-col>
            <v-col cols="6" md="6" lg="6" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="
                  selectedAssignmentRefPathInDialog = null;
                  selectedAssignmentInDialog = null;
                  selectedAssignmentShowDialog = false;
                "
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 90%" class="px-0">
          <XShalgaltEyeshView
            :xpath="selectedAssignmentRefPathInDialog"
          ></XShalgaltEyeshView>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            variant="text"
            color="red"
            dark
            @click="
              selectedAssignmentRefPathInDialog = null;
              selectedAssignmentInDialog = null;
              selectedAssignmentShowDialog = false;
            "
          >
            Xааx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import XShalgaltEyeshView from "./XShalgaltEyeshView";
import XShalgaltResults from "./XShalgaltResults";
import XShowAssignmentAllResults from "./XShowAssignmentAllResults";
import { sync } from "vuex-pathify";
import moment from "moment";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
export default {
  components: {
    XShalgaltResults,
    XShowAssignmentAllResults,
    XShalgaltEyeshView,
  },
  data: function () {
    return {
      sorilCoverages: null,
      addClassGroupsDialog: false,
      selectedAssignmentInDialog: null,
      selectedAssignmentRefPathInDialog: null,
      selectedAssignmentShowDialog: false,

      confirmedSchoolReport: null,
      schools: null,
      openSorilResults: false,
      schoolScoreData: null,
      closedAverageScore: null,
      rules: {
        required: (value) => !!value || "Шаардлагатай.",
      },
      deleteDialog: false,
      editedItem: {},
      messageNotification: null,
      newDialog: false,
      lessonCategories: null,
      defaultCategories: [
        {
          name: "1",
          abc: true,
          catIndex: 0,
          categoryScoreRequired: false,
          givingScorePerQuestion: true,
          withKeys: false,
        },
        {
          name: "2.1",
          abc: false,
          catIndex: 1,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
        {
          name: "2.2",
          abc: false,
          catIndex: 2,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
        {
          name: "2.3",
          abc: false,
          catIndex: 3,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
        {
          name: "2.4",
          abc: false,
          catIndex: 4,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
          withKeys: true,
        },
      ],
    };
  },
  computed: {
    ...sync("*"),
    selectedLessons() {
      console.log(this.assignment);
      return this.$attrs.filteredLessons.filter(
        (lesson) =>
          lesson.courseInfo.SUBJECT_AREA_ID ==
          this.assignment.courseInfo.SUBJECT_AREA_ID
      );
    },
    _isNotEyesh() {
      return fb.EYESH_APP ? false : true;
    },
    // _getProgress() {
    //   var number = 0;
    //   if (this.lessonCategories) {
    //     for (var lcategory of this.lessonCategories) {
    //       console.log(
    //         lcategory.ref,
    //         this.assignment.categoryRef.path,
    //         lcategory.ref.path == this.assignment.categoryRef.path
    //       );
    //       if (lcategory.ref.path == this.assignment.categoryRef.path) {
    //         number = lcategory.counter;
    //         break;
    //       }
    //     }
    //   }
    //   return number;
    // },
  },
  watch: {
    addClassGroupsDialog() {
      console.log(this.assignment);
      var found = this.$attrs.filteredLessons.filter(
        (ll) =>
          ll.courseInfo.SUBJECT_AREA_ID ==
          this.assignment.courseInfo.SUBJECT_AREA_ID
      );
      this.assignment.sorilClassGroups = found ? found : [];
    },
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.sorilCoverages = [
      { name: "Xичээлийн xүрээнд", sorilIndex: 3 },
      { name: "Сургуулийн xүрээнд", sorilIndex: 2 },
    ];
    if (this.userData.schoolIndex) {
      this.sorilCoverages.push({ name: "Тусгай сорил", sorilIndex: 1 });
    }
    if (this.userData.role == "aspecialist") {
      fb.db
        .collection("schools")
        .where("schoolIndex", "==", this.userData.schoolIndex)
        .get()
        .then((docss) => {
          this.schools = [];
          var counter = 1;
          docss.forEach((doc) => {
            let school = doc.data();
            school.id = doc.id;
            school.ref = doc.ref;
            school.index = counter;
            counter++;
            this.schools.push(school);
          });
        });
    } else if (this.userData.role == "eyeshadmin" && this.userData.school) {
      this._calAverage();
      this.assignment.ref
        .collection("schools-confirmed-report")
        .doc(this.userData.school.id)
        .collection("assignments")
        .doc(this.assignment.id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.confirmedSchoolReport = doc.data();
            this.confirmedSchoolReport.ref = doc.ref;
            this.confirmedSchoolReport.id = doc.id;
          }
        });
    }
  },
  mounted() {},
  methods: {
    _print(assignment) {
      console.log(assignment.ref.path);
    },
    _isPublicTo(lesson) {
      var found = null;
      if (this.assignment.openToClassGroupsRefs) {
        found = this.assignment.openToClassGroupsRefs.find(
          (el) => el.path == lesson.classGroups[0].classGroupRef.path
        );
      }
      if (found) return true;
      else false;
    },
    _openAssignmentToLesson(lesson) {
      var cg = lesson.classGroups[0];
      cg.programData = null;

      var isPublic = this._isPublicTo(lesson);
      if (isPublic) {
        this.assignment.ref
          .update({
            openToClassGroupsRefs: fb.firestore.FieldValue.arrayRemove(
              lesson.classGroups[0].classGroupRef
            ),
            openToClassGroupsRefPaths: fb.firestore.FieldValue.arrayRemove(
              lesson.classGroups[0].classGroupRef.path
            ),
            openToClassGroups: fb.firestore.FieldValue.arrayRemove(
              lesson.classGroups[0]
            ),
            public:
              this.assignment.openToClassGroupsRefs.length == 1 ? false : true,
          })
          .then(() => {
            this._isPublicTo(lesson);
          });
      } else {
        this.assignment.ref
          .update({
            openToClassGroupsRefs: fb.firestore.FieldValue.arrayUnion(
              lesson.classGroups[0].classGroupRef
            ),
            openToClassGroupsRefPaths: fb.firestore.FieldValue.arrayUnion(
              lesson.classGroups[0].classGroupRef.path
            ),
            openToClassGroups: fb.firestore.FieldValue.arrayUnion(
              lesson.classGroups[0]
            ),
            public: true,
          })
          .then(() => {
            this._isPublicTo(lesson);
          });
      }
    },
    _getLessonClassGroupsName(lesson) {
      var str = null;
      if (!lesson.isCollected) {
        for (const gg of lesson.classGroups) {
          str = str ? str + ", " + gg.classGroupName : gg.classGroupName;
        }
      } else if (lesson.subLessons) {
        // console.log(lesson.isCollected, lesson.name2, lesson);
        for (const xx of lesson.subLessons) {
          if (xx.classGroups && xx.classGroups[0]) {
            str = str
              ? str + ", " + xx.classGroups[0].classGroupFullName
              : xx.classGroups[0].classGroupFullName;
          }
        }
      }
      return str;
    },
    _showAllowed() {
      return ["dr.ts.tuvshintur@gmail.com", "granditxxk@gmail.com"].includes(
        this.userData.email
      );
    },
    _detail(item) {
      if (item.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltEditing",
          params: {
            xpath: item.ref.path,
          },
        });
      } else if (item.assignmentType == 2) {
        //@accessTODO
        if (
          (this.userData && item.createdByEmail == this.userData.email) ||
          (this.userData.role == "eyeshadmin" &&
            item.createdBySchoolRef &&
            item.createdBySchoolRef.path == this.userData.school.ref.path) ||
          this.userData.email == "dr.ts.tuvshintur@gmail.com" ||
          this.userData.email == "eyeshplatform@gmail.com"
        ) {
          if (item.setupped == false) {
            var batch = fb.db.batch();
            for (var cat of this.defaultCategories) {
              var catRef = item.ref.collection("categories").doc(cat.name);
              batch.set(catRef, cat);
            }
            batch.commit().then(() => {
              item.ref.update({ setupped: true }).then(() => {
                this.$router.push({
                  name: "XShalgaltEditingEyesh",
                  params: {
                    xpath:
                      item._originalAssignmentRefPath &&
                      item._notOrginal == true
                        ? item._originalAssignmentRefPath
                        : item.ref.path,
                    fromEYESH: this.fromEYESH,
                  },
                });
              });
            });
          } else {
            this.$router.push({
              name: "XShalgaltEditingEyesh",
              params: {
                xpath:
                  item._originalAssignmentRefPath && item._notOrginal == true
                    ? item._originalAssignmentRefPath
                    : item.ref.path,
              },
            });
          }
        } else {
          this.selectedAssignmentInDialog = item;
          this.selectedAssignmentRefPathInDialog =
            item._originalAssignmentRefPath && item._notOrginal == true
              ? item._originalAssignmentRefPath
              : item.ref.path;
          this.selectedAssignmentShowDialog = true;

          // this.$router.push({
          //   name: "XShalgaltEyeshViewDialog",
          //   params: {
          //     xpath: (item._originalAssignmentRefPath&&item._notOrginal==true)? item._originalAssignmentRefPath: item.ref.path,
          //   },
          // });
        }
      }
    },
    async _calAverage() {
      var resultData = {};
      var xx = { size: 0 };
      if (this.assignment.categoryRefPath) {
        xx = await fb.db
          .doc(this.assignment.categoryRefPath)
          .collection("lesson-students")
          .where("ppschool", "==", this.userData.school.ref.path)
          .get();
      }
      var query = this.assignment.ref
        .collection("results-simplified")
        .orderBy("totalScore", "desc");

      if (this.userData.school) {
        query = query.where("schoolRef", "==", this.userData.school.ref);
      }
      query.onSnapshot(async (docs) => {
        this.results = [];
        if (!docs.empty) {
          var counter = 1;
          var sumOfAverageTotalScores = 0;
          docs.forEach((doc) => {
            let result = doc.data();
            result.ref = doc.ref;
            result.id = doc.id;
            result.index = counter;
            sumOfAverageTotalScores =
              sumOfAverageTotalScores + result.totalScore;
            // result.percent =
            //   ((result.totalScore / this.totalScore) * 100).toFixed(0) + "%";

            // if (this.maxTotalScore < result.totalScore)
            //   this.maxTotalScore = result.totalScore;
            counter++;
            // if(this.userData.role='eyeshadmin'&&this.userData.school){

            // }
            this.results.push(result);
          });
          resultData = {
            totalAverage: sumOfAverageTotalScores / this.results.length,
            numberOfParticipants: this.results.length,
            assignmentId: this.assignment.id,
            assignmentName: this.assignment.name,
            assignment: this.assignment,
            assignmentRefPath: this.assignment.ref.path,
            schoolId: this.userData.school.id,
            schoolName: this.userData.school.name,
            schoolRefPath: this.userData.school.ref.path,
            school: this.userData.school,
            calculatedAt: new Date(),
            numberOfInterested: xx.size,
          };
          this.assignment.ref
            .collection("schools-confirmed-report")
            .doc(this.userData.school.id)
            .collection("assignments")
            .doc(this.assignment.id)
            .set(resultData, { merge: true });
          this.userData.school.ref
            .collection("soril-confirmed-results")
            .doc(this.assignment.id)
            .set(resultData, { merge: true });
        } else {
          resultData = {
            totalAverage: 0,
            numberOfParticipants: 0,
            assignmentId: this.assignment.id,
            assignmentName: this.assignment.name,
            assignment: this.assignment,
            assignmentRefPath: this.assignment.ref.path,
            schoolId: this.userData.school.id,
            schoolName: this.userData.school.name,
            schoolRefPath: this.userData.school.ref.path,
            school: this.userData.school,
            calculatedAt: new Date(),
            numberOfInterested: xx.size,
          };
          this.assignment.ref
            .collection("schools-confirmed-report")
            .doc(this.userData.school.id)
            .collection("assignments")
            .doc(this.assignment.id)
            .set(resultData, { merge: true });
          this.userData.school.ref
            .collection("soril-confirmed-results")
            .doc(this.assignment.id)
            .set(resultData, { merge: true });
        }
      });
    },
    _log() {
      console.log(this.assignment.ref.path);
    },
    _closeSoril(assignment) {
      if (this.userData.school) {
        var counter = 1;
        var sumOfAverageTotalScores = 0;
        var maxTotalScore = 0;
        assignment.ref
          .collection("results-simplified")
          .orderBy("totalScore", "desc")
          .onSnapshot(async (docs) => {
            this.results = [];
            if (!docs.empty) {
              docs.forEach((doc) => {
                let result = doc.data();
                result.ref = doc.ref;
                result.id = doc.id;
                result.index = counter;
                result.percent =
                  ((result.totalScore / this.totalScore) * 100).toFixed(0) +
                  "%";

                if (result.skipped == false) {
                  sumOfAverageTotalScores =
                    sumOfAverageTotalScores + result.totalScore;
                  if (maxTotalScore < result.totalScore)
                    maxTotalScore = result.totalScore;
                }
                counter++;
              });
            } //after loop
            // console.log(sumOfAverageTotalScores);
            // console.log(counter);
            // console.log(maxTotalScore);

            if (this.schoolScoreData) {
              assignment.ref
                .collection("school-closed-results")
                .doc(this.userData.school.id)
                .delete()
                .then(() => {
                  this.schoolScoreData = null;
                });
            } else {
              assignment.ref
                .collection("school-closed-results")
                .doc(this.userData.school.id)
                .set({
                  averageScore: sumOfAverageTotalScores / counter,
                  sumOfAverageTotalScores: sumOfAverageTotalScores,
                  maxTotalScore: maxTotalScore,
                  numberOfParticipants: counter,
                  createdAt: new Date(),
                  createdByRef: this.userData.ref,
                  createdByName: this.userData.firstName,
                })
                .then(() => {});
            }
          });
      }
    },
    _copyTest(assignment) {
      assignment.ref.get().then((test) => {
        let assignmentData = test.data();

        // console.log(assignment.id);
        assignmentData.createdByRef = null;
        assignmentData.selectedLessonCategory = null;
        assignmentData.ref = null;
        assignmentData.categoryRef = null;
        assignmentData.description = null;
        // console.log(assignment.ref.path);
        assignmentData.createdByEmail = "granditxxk@gmail.com";
        assignmentData.schoolIndex = null;
        assignmentData.createdBySchoolRef = null;
        assignmentData.forAllSchool = true;
        assignmentData.examScope = null;
        assignmentData.createdBySchoolName = null;

        fb.dbeyesh800
          .collection("assignmentsdatabase")
          .doc(assignmentData.id)
          .set(assignmentData)
          .then(() => {
            fb.db
              .doc(assignment.ref.path)
              .collection("categories")
              .get()
              .then((categories) => {
                categories.forEach((category) => {
                  let categoryData = category.data();
                  categoryData.ref = null;
                  categoryData.questions = null;
                  // console.log(categoryData)
                  fb.dbeyesh800
                    .collection("assignmentsdatabase")
                    .doc(assignmentData.id)
                    .collection("categories")
                    .doc(category.id)
                    .set(categoryData)
                    .then(() => {
                      fb.db
                        .doc(category.ref.path)
                        .collection("questions")
                        .get()
                        .then((questions) => {
                          questions.forEach((question) => {
                            let questionData = question.data();

                            //questionData.ref = questionData.ref.path;
                            questionData.ref = null;
                            fb.dbeyesh800
                              .collection("assignmentsdatabase")
                              .doc(assignmentData.id)
                              .collection("categories")
                              .doc(category.id)
                              .collection("questions")
                              .doc(questionData.id)
                              .set(questionData)
                              .then(() => {
                                console.log("complete");
                              });
                          });
                        });
                    });
                });
              });
          });
      });
    },
    _copyToZavkhan(assignment) {
      assignment.ref.get().then((test) => {
        let assignmentData = test.data();

        // console.log("assignmentsdatabase/" + assignment.id);
        assignmentData.createdByRef = null;
        assignmentData.selectedLessonCategory = null;
        assignmentData.ref = null;
        assignmentData.categoryRef = null;
        assignmentData.description = null;
        // console.log(assignment.ref.path);
        assignmentData.createdByEmail = "granditxxk@gmail.com";
        assignmentData.schoolIndex = null;
        assignmentData.createdBySchoolRef = null;
        assignmentData.forAllSchool = true;
        assignmentData.examScope = null;
        assignmentData.createdBySchoolName = null;

        fb.zavkhanDB
          .collection("assignmentsdatabase")
          .doc(assignmentData.id)
          .set(assignmentData)
          .then(() => {
            fb.db
              .doc(assignment.ref.path)
              .collection("categories")
              .get()
              .then((categories) => {
                categories.forEach((category) => {
                  let categoryData = category.data();
                  categoryData.ref = null;
                  categoryData.questions = null;
                  // console.log(categoryData)
                  fb.zavkhanDB
                    .collection("assignmentsdatabase")
                    .doc(assignmentData.id)
                    .collection("categories")
                    .doc(category.id)
                    .set(categoryData)
                    .then(() => {
                      fb.db
                        .doc(category.ref.path)
                        .collection("questions")
                        .get()
                        .then((questions) => {
                          questions.forEach((question) => {
                            let questionData = question.data();

                            //questionData.ref = questionData.ref.path;
                            questionData.ref = null;
                            // console.log(questionData);

                            fb.zavkhanDB
                              .collection("assignmentsdatabase")
                              .doc(assignmentData.id)
                              .collection("categories")
                              .doc(category.id)
                              .collection("questions")
                              .doc(questionData.id)
                              .set(questionData)
                              .then(() => {
                                console.log("complete");
                              });
                          });
                        });
                    });
                });
              });
          });
      });
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear().toString().slice(-2);
      let month = (date.getMonth() + 1).toString();
      let day = date.getDate().toString();
      if (month.length === 1) {
        month = "0" + month;
      }
      if (day.length === 1) {
        day = "0" + day;
      }

      return `${year}${month}${day}`;
    },
    async _copyToPublicDatabase(assignment, collName) {
      // console.log(assignment, assignment.createdAt.toDate(), fb.EYESH_APP);
      // console.log(collName + "/" + assignment.id)
      // console.log(assignment.categoryRefPath)
      assignment["origName"] = assignment.name;
      assignment["origNote"] = assignment.name;
      assignment.name = assignment.name + "-" + this.getCurrentDate(new Date());

      if (!assignment.yeshLessonCode) {
        var xx = await fb.dbeyesh800.doc(assignment.categoryRefPath).get();
        if (xx.exists) {
          assignment.yeshLessonCode = xx.data().yeshLessonCode
            ? xx.data().yeshLessonCode
            : null;
          assignment.name = xx.data().yeshLessonCode
            ? xx.data().name.replace("ЭЕШ-", "") +
              "-" +
              this.getCurrentDate(new Date())
            : null;
        }
      }
      console.log(assignment.yeshLessonCode, assignment.name);
      assignment.categoryRef = null;
      assignment.createdByRef = null;
      assignment.selectedLessonCategory = null;
      assignment.deleted = false;
      var originalAssignmentRef = assignment.ref;
      assignment.ref = null;
      assignment.publishedAt = new Date();
      if (!assignment.cycleIndex) {
        assignment.cycleIndex = 6;
      }
      assignment.openForAllSchool = false;
      if (assignment.openForAllSchool == false) {
        // console.log(assignment, originalAssignmentRef.path)
        fb.dbeyesh800
          .collection(collName)
          .doc(assignment.id)
          .set(assignment, { merge: true })
          .then(async () => {
            // console.log(collName + "/" + assignment.id);
            var batch = fb.dbeyesh800.batch();
            var oldCats = await originalAssignmentRef
              .collection("categories")
              .get();

            if (oldCats.empty == false) {
              oldCats.docs.forEach(async (category) => {
                let categoryData = category.data();
                categoryData.ref = null;
                categoryData.questions = null;
                batch.set(
                  fb.dbeyesh800
                    .collection(collName)
                    .doc(assignment.id)
                    .collection("categories")
                    .doc(category.id),
                  categoryData
                );
                var oldquestions = await fb.db
                  .doc(category.ref.path)
                  .collection("questions")
                  .get();
                if (oldquestions.empty == false) {
                  for (const question of oldquestions.docs) {
                    let questionData = question.data();
                    questionData.ref = null;
                    batch.set(
                      fb.dbeyesh800
                        .collection(collName)
                        .doc(assignment.id)
                        .collection("categories")
                        .doc(category.id)
                        .collection("questions")
                        .doc(questionData.id),
                      questionData
                    );
                  }
                }
              });
            }
            setTimeout(() => {
              batch.commit().then(() => {
                assignment.openForAllSchool = !assignment.openForAllSchool;
                this.$forceUpdate();
                console.log("completed");
              });
            }, 2000);
          });
      } else {
        // assignment.ref.update({ deleted: true }).then(() => {
        //   assignment.openForAllSchool = !assignment.openForAllSchool;
        //   this.$forceUpdate();
        // });
        console.log("No ...");
        assignment.openForAllSchool = !assignment.openForAllSchool;
        this.$forceUpdate();
      }
    },
    _seeResults() {
      // console.log(assignment, assignment.assignmentType);
      if (this.assignment.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltResults",
          params: { item: this.assignment },
        });
      } else {
        this.$router.push({
          name: "StudentShowAssignmentAllResults",
          params: {
            sorilId: this.assignment.ref.path,
            maxNumber: this.assignment.yyy,
          },
        });
      }
    },
    _isAllowedToEdit() {
      if (this.userData.email == this.asuulga.createdByEmail) return true;
      else return false;
    },
    _shareLink(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          testingtesting: false,
          fromEYESH: this.fromEYESH,
          backRouterPath: "/pages/dashboards/myassignments",
        },
      });
    },
    _notShowAnswers(assignment) {
      console.log(assignment.ref.path);
      assignment.ref.update({
        showingCorrectAnswers: !assignment.showingCorrectAnswers,
      });
    },
    _finishAssignment(assignment) {
      if (assignment.finished)
        assignment.ref.update({ finished: !assignment.finished });
      else assignment.ref.update({ finished: true });
    },
    _redirectResults(soril) {
      if (soril.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltResults",
          params: { item: soril },
        });
      } else {
        this.$router.push({
          name: "StudentShowAssignmentAllResults",
          params: { sorilId: soril.ref.path },
        });
      }
    },
    _makePublic(assignment) {
      if (assignment.public == false && !assignment.finalScore) {
        this.$swal({
          title: "Оноо оруулна уу!",
          text: "Оноогүй учир нээлттэй болгоx боломжгүй!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
      } else {
        assignment.public = !assignment.public;
        assignment.ref.update(assignment);
      }
    },
    _deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    async _deleteOK() {
      if (this.editedItem) {
        console.log(this.editedItem.ref.path);
        if (this.editedItem.deleteCompletely && this.editedItem.ref) {
          var batch = fb.db.batch();
          var cats = await this.editedItem.ref.collection("categories").get();
          if (!cats.empty) {
            for (const doc of cats.docs) {
              var cat = await doc.ref.get();
              if (cat.exists) {
                var questions = await cat.ref.collection("questions").get();
                if (!questions.empty) {
                  for (const qq of questions.docs) {
                    batch.delete(qq.ref);
                  }
                }
              }
              batch.delete(cat.ref);
            }
          }

          batch.delete(this.editedItem.ref);
          batch.commit();

          // this.editedItem.ref.update({ deleted: true }).then(() => {
          //   this.deleteDialog = false;
          //   this.editedItem = {};
          //   location.reload();
          // });
        } else {
          this.editedItem.ref.update({ deleted: true }).then(() => {
            this.deleteDialog = false;
            this.editedItem = {};
            location.reload();
          });
        }
      }
    },
    _editItem(item) {
      this.newDialog = true;
      this.editedItem = Object.assign({}, item);
    },
    saveItem() {
      if (this.editedItem.ref) {
        this.editedItem.name = this.editedItem.name.trim();
        this.editedItem.userTypeId = { name: "Сурагч", userTypeId: 1 };
        this.editedItem.updateAt = new Date();
        this.editedItem.ref.update(this.editedItem).then(() => {
          this._closeEdit();
        });
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },

    _isAllowed(assignment) {
      if (
        (this.userData && assignment.createdByEmail == this.userData.email) ||
        this.userData.role == "superadmin" ||
        this.userData.role == "eyeshadmin" ||
        this.userData.role == "aspecialist"
      )
        return true;
      // else if (
      //   // this.userData.role == "aspecialist" ||
      //   // this.userData.role == "eyeshadmin" ||
      //   this.userData.role == "superadmin"
      // )
      //   return true;
      else return false;
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.defaultCard {
  color: grey !important;
}

.defaultCard:hover {
  color: inherit !important;
}

.average-score-divider {
  width: 80%;
  height: 5px;
  background: red;
}
</style>
